<template>
    <div>
      <!-- Start Header Area -->
      <Header />
      <!-- End Header Area -->

    <!-- Start Future Area -->
     <div class="service-area ptb--120 bg_color--5" id="future">
      <v-container>
        <v-row class="mb--30 mt--50">
          <v-col cols="12">
            <div class="text-center section-title">
              <!-- <span class="subtitle">Seid gespannt auf die nächste Phase</span> -->
              <h2 class="heading-title">Impressum</h2>
              <p class="description">
                Hochschule Trier - Trier University of Applied Sciences <br />
                Schneidershof <br />
                54293 Trier <br />
                <br/>
                Inhaltlich Verantwortliche gemäß § 55 Abs. 2 RStV:<br/>
                Prof. Dr. Jan-Thomas Bachmann <br/>
                +49 651 8103-282 <br/>
                <a href="mailto:Ja.Bachmann@hochschule-trier.de">Ja.Bachmann@hochschule-trier.de</a><br/>
                Schneidershof | Gebäude K | Raum 203 <br/>
                54293 Trier <br/>
                <br/>
                Dies ist ein Studienprojekt der Veranstaltung "Opportunity Recognition" im Studiengang "Entrepreneurship (M.A.)". 
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Future Area -->
  
      <!-- Start Footer Area  -->
      <Footer />
      <!-- End Footer Area  -->
    </div>
  </template>
  
  <script>
    import Header from "../../components/ellavate/Header_ext";
    import Slider from "../../components/ellavate/Slider";
    import About from "../../components/ellavate/About";
    import Contact from "../../components/ellavate/Contact";
    import Footer from "../../components/ellavate/Footer";


    // import PortfolioTwo from "../../components/portfolio/PortfolioFour";
    // import PricingPlan from "../../components/pricing-plan/PricingPlan";
  
    export default {
      components: {
        Header,
        Slider,
        About,
        Contact,
        Footer,

        // PortfolioTwo,
        // PricingPlan,
        
      },
      data() {
        return {};
      },
    };
  </script>
  
  <style lang="scss">
    .feather-menu {
      color: #1d1d24;
    }
  </style>
  